<template>
  <b-modal
    id="detail-ticket"
    :titleHtml="titelModal"
    hide-footer
    size="lg"
    scrollable
  >
    <div>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col md="6">
              <p class="text-dark font-size-h5 font-weight-bold">
                Thông tin người tạo
              </p>
              <div class="d-flex flex-column flex-md-row mb-5">
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between font-size-md mb-3">
                    <span class="font-weight-bold mr-10">Tên:</span>
                    <span v-if="!ticket.userCreateTicket">---</span>
                    <span class="text-right" v-else>
                      {{ ticket.userCreateTicket.employee.fullName }}
                      <template v-if="ticket.userCreateTicket.employee.code">
                        - {{ ticket.userCreateTicket.employee.code }}
                      </template>
                    </span>
                  </div>

                  <div
                    class="d-flex justify-content-between font-size-md mb-3"
                    v-if="ticket.userCreateTicket"
                  >
                    <span class="font-weight-bold mr-10">Cửa hàng:</span>
                    <span v-if="!ticket.userCreateTicket.employee.store"
                      >---</span
                    >
                    <span class="text-right" v-else>{{
                      ticket.userCreateTicket.employee.store.name
                    }}</span>
                  </div>
                  <div
                    class="d-flex justify-content-between font-size-md"
                    v-if="ticket.userCreateTicket"
                  >
                    <span class="font-weight-bold mr-10">Chức vụ:</span>
                    <span v-if="!ticket.userCreateTicket.employee.jobTitle"
                      >---</span
                    >
                    <span class="text-right" v-else>{{
                      ticket.userCreateTicket.employee.jobTitle.name ?? '-'
                    }}</span>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col md="6">
              <p class="text-dark font-size-h5 font-weight-bold">
                Thông tin người hỗ trợ
              </p>
              <div class="d-flex flex-column flex-md-row mb-5">
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between font-size-md mb-3">
                    <span class="font-weight-bold mr-10">Tên:</span>
                    <span v-if="!ticket.userAssigneeTicket">---</span>
                    <span class="text-right" v-else>
                      {{ ticket.userAssigneeTicket.employee.fullName ?? '-' }}
                      <template v-if="ticket.userAssigneeTicket.employee.code">
                        - {{ ticket.userAssigneeTicket.employee.code }}
                      </template>
                    </span>
                  </div>

                  <div
                    class="d-flex justify-content-between font-size-md mb-3"
                    v-if="ticket.userAssigneeTicket"
                  >
                    <span class="font-weight-bold mr-10">Cửa hàng:</span>
                    <span v-if="!ticket.userAssigneeTicket.employee.store"
                      >---</span
                    >
                    <span class="text-right" v-else>{{
                      ticket.userAssigneeTicket.employee.store.name ?? '-'
                    }}</span>
                  </div>

                  <div
                    class="d-flex justify-content-between font-size-md"
                    v-if="ticket.userAssigneeTicket"
                  >
                    <span class="font-weight-bold mr-10">Chức vụ:</span>
                    <span v-if="!ticket.userAssigneeTicket.employee.jobTitle"
                      >---</span
                    >
                    <span class="text-right" v-else>{{
                      ticket.userAssigneeTicket.employee.jobTitle.name ?? '-'
                    }}</span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="
              ticket.body &&
                ticket.TicketTypeSetting.functionAction ===
                  FUNCTION_ACTION_TICKET.POINT_TRANSFER
            "
          >
            <!-- <b-col md="12">
              <p class="text-dark font-size-h5 font-weight-bold">
                Ghi chú
              </p>
              <div class="d-flex flex-column flex-md-row mb-5">
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between font-size-md mb-3">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mr-10">
                        Số điện thoại chuyển:
                      </span>
                      <strong>{{ ticket.body.phoneNumberTransfer }}</strong>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mr-10">
                        Số điểm chuyển:
                      </span>
                      <strong>{{ ticket.body.point }}</strong>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mr-10">
                        Số điện thoại nhận:
                      </span>
                      <strong>{{ ticket.body.phoneNumberReceive }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </b-col> -->

            <TransferPoint :ticket="ticket" />
          </b-row>
          <b-row
            v-if="
              ticket.body &&
                ticket.TicketTypeSetting.functionAction ===
                  FUNCTION_ACTION_TICKET.ADD_ORDER_FOR_BILL
            "
          >
            <AddOrderForBill :ticket="ticket" />
          </b-row>
          <b-row
            v-if="
              ticket.body &&
                ticket.TicketTypeSetting.functionAction ===
                  FUNCTION_ACTION_TICKET.PHONE_NUMBER_CHANGE
            "
          >
            <!-- <b-col md="12">
              <p class="text-dark font-size-h5 font-weight-bold">
                Ghi chú
              </p>
              <div class="d-flex flex-column flex-md-row mb-5">
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between font-size-md mb-3">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mr-10">
                        Số điện thoại chuyển:
                      </span>
                      <strong>{{ ticket.body.phoneNumberTransfer }}</strong>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mr-10">
                        Số điểm chuyển:
                      </span>
                      <strong>{{ ticket.body.point }}</strong>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mr-10">
                        Số điện thoại nhận:
                      </span>
                      <strong>{{ ticket.body.phoneNumberReceive }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </b-col> -->

            <ChangePhoneNumber :ticket="ticket" />
          </b-row>

          <b-row>
            <b-col md="12 mb-5">
              <p class="text-dark font-size-h5 font-weight-bold">Nội dung</p>
              <div>
                {{ ticket.summary }}
              </div>
            </b-col>
          </b-row>
          <b-row v-if="ticket.extra && ticket.extra.length">
            <b-col md="12 mb-5">
              <p class="text-dark font-size-h5 font-weight-bold">Hình ảnh</p>
              <div>
                <vue-easy-lightbox
                  escDisabled
                  :visible="previewFile.visible"
                  :imgs="previewFile.imgs"
                  :index="previewFile.index"
                  @hide="handleHidePreviewFile"
                ></vue-easy-lightbox>

                <vue-easy-lightbox
                  escDisabled
                  :visible="previewAttachment.visible"
                  :imgs="previewAttachment.imgs"
                  :index="previewAttachment.index"
                  @hide="handleHidePreviewAttachment"
                ></vue-easy-lightbox>
                <!--File Previews-->
                <div class="d-flex upload-image">
                  <div
                    v-for="(item, index) in attachments"
                    :key="index"
                    class="img-container align-items-start"
                  >
                    <img
                      v-if="typecheck(item.fileName)"
                      :src="item.url"
                      class="preview-img"
                      width="80"
                      height="100"
                      @click="showMultiplePreViewAttachment(index)"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <p class="text-dark font-size-h5 font-weight-bold">Trạng thái</p>
              <b-form-group>
                <treeselect
                  :options="statusOptions"
                  :multiple="false"
                  placeholder="Chọn trạng thái"
                  noResultsText="Không có kết quả"
                  :match-keys="['label']"
                  openDirection="bottom"
                  :clearable="false"
                  v-model="ticketUpdate.status"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span :title="node.label"> {{ node.label }}</span>
                  </label>
                </treeselect>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <p class="text-dark font-size-h5 font-weight-bold">Thời gian</p>
              <div class="d-flex flex-column flex-md-row mb-5">
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between font-size-md mb-3">
                    <span class="font-weight-bold mr-10">Ngày tạo:</span>
                    <span v-if="!ticket.createdAt">---</span>
                    <span class="text-right" v-else>
                      {{ formatDate(ticket.createdAt, 'DD-MM-YYYY HH:mm:ss') }}
                    </span>
                  </div>

                  <div class="d-flex justify-content-between font-size-md mb-3">
                    <span class="font-weight-bold mr-10">Ngày cập nhật:</span>
                    <span v-if="!ticket.updatedAt">---</span>
                    <span class="text-right" v-else>
                      {{ formatDate(ticket.updatedAt, 'DD-MM-YYYY HH:mm:ss') }}
                    </span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="ticketUpdate.status === TICKET_STATUS.REJECT">
            <b-col>
              <b-form-group>
                <label>Lý do từ chối</label>
                <b-form-textarea
                  size="sm"
                  class="input-style"
                  :placeholder="'Thêm lý do từ chối'"
                  :rows="6"
                  :max-rows="6"
                  v-model="ticketUpdate.reason"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-row>
        <b-col>
          <b-button
            style="width: 70px"
            variant="primary"
            size="sm"
            class="mr-3"
            @click="updateStatusTicket()"
            >Lưu</b-button
          >
          <b-button
            style="width: 70px"
            variant="secondary"
            size="sm"
            class="mr-3"
            @click="$bvModal.hide('detail-ticket')"
            >Hủy</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<style lang="scss">
#detail-ticket {
  .modal-header {
    padding: 0.8rem 1.75rem;
  }
  #history-table tbody tr td > span {
    display: block;
  }
  #history-table tbody tr td > b {
    width: max-content;
  }
  .alert-warning {
    color: #855700;
    background-color: #ffeecc;
    border-color: #ffe7b8;
  }
  .preview-img {
    max-width: 80px;
    padding: 10px;
    object-fit: contain;
    border: 1px solid #eeecff;
    border-radius: 5px;
  }
  .preview-img-container {
    border: 2px dashed #eeecff;
    padding: 2rem 1rem;
  }
  .img-container {
    position: relative;
    display: inline-block;
    width: 80px;
    margin: 0px 10px;
  }
  .upload-image .img-container:first-child {
    margin-left: 0px;
  }
  .upload-image .img-container:last-child {
    margin-right: 0px;
  }
}
</style>
<script>
import { validationMixin } from 'vuelidate';
// import ApiService from '@/core/services/api.service';
import { checkPermissions } from '../../../utils/saveDataToLocal';
import {
  convertPrice,
  makeToastFaile,
  makeToastSuccess,
  currencyMask,
} from '../../../utils/common';
import { assign, isEmpty } from 'lodash';
import { PREORDER_STATUS } from '../../../utils/constants';
import { TICKET_STATUS, FUNCTION_ACTION_TICKET } from '@/utils/enum';
import { TICKET_STATUS_NAME } from '@/utils/enum-name';

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import axios from 'axios';
// import { cmdUrl } from '@/utils/apiUrl';
// import JwtService from '@/core/services/jwt.service';
import VueEasyLightbox from 'vue-easy-lightbox';
import { formatDate } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import TransferPoint from './type/transfer-point.vue';
import AddOrderForBill from './type/add-order-for-bill.vue';
import ChangePhoneNumber from './type/change-phone-number.vue';

export default {
  components: {
    AddOrderForBill,
    TransferPoint,
    ChangePhoneNumber,
    Treeselect,
    VueEasyLightbox,
  },
  props: ['params', 'stores', 'image'],
  mixins: [validationMixin],
  data() {
    return {
      FUNCTION_ACTION_TICKET,
      TICKET_STATUS,
      mask: currencyMask,
      ticket: {
        id: null,
        userCreated: null,
        userAssignee: null,
        type: null,
        extra: null,
        summary: null,
        reason: null,
        status: null,
        createdAt: null,
        updatedAt: null,
        TicketTypeSetting: null,
        userCreateTicket: null,
        userAssigneeTicket: null,
        body: null,
      },
      preOrder: {
        orderId: null,
        storeId: null,
        customerId: null,
        customerMobile: null,
        customerName: null,
        customerEmail: null,
        customerAddress: null,
        formId: null,
        status: 1,
        notes: null,
        productId: null,
        productName: null,
        productNameSearch: null,
        searchProductType: 1,
        installMoneyAccountId: null,
        installMoneyCode: null,
        installMoneyAmount: 0,
        partnerPaymentStatus: null,
        attributes: [],
        products: [],
        totalPointText: null,
        id: null,
      },
      preOrdersProductFields: [
        {
          key: 'productNameSystem',
          label: 'Tên sản phẩm',
          thClass: 'text-center',
        },
        {
          key: 'barCode',
          label: 'Barcode',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'sellingPrice',
          label: 'Số tiền',
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'actions',
          label: '',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ],
      preOrdersProduct: [],
      optionStatus: PREORDER_STATUS.filter(
        (status) => status.checked === false,
      ),
      onLoading: false,
      filteredStores: [],
      titelModal: null,
      filteredInstallments: [],
      filteredProducts: [],
      filteredCustomers: [],
      histories: [],
      statusOptions: [
        { id: 0, label: 'Mới' },
        { id: 1, label: 'Ghi nhận thông tin' },
        { id: 2, label: 'Đang xử lí' },
        { id: 3, label: 'Hoàn thành' },
        { id: 4, label: 'Từ chối' },
      ],
      attachments: [],
      ticketUpdate: {
        id: null,
        status: -1,
        reason: null,
      },
      previewAttachment: {
        visible: false,
        imgs: '',
        index: 0,
      },
      previewFile: {
        visible: false,
        imgs: '',
        index: 0,
      },
    };
  },
  computed: {},
  created() {},
  watch: {
    $props: {
      handler() {
        this.initialParams();
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {},
  methods: {
    formatDate,
    convertPrice,
    checkPermissions,

    initialParams() {
      if (!isEmpty(this.params)) {
        const payloadTicket = assign(this.ticket, this.params);
        this.ticket = payloadTicket;
        this.ticket.extra = JSON.parse(this.ticket.extra);
        this.ticket.body = JSON.parse(this.ticket.body);
        this.ticketUpdate['id'] = this.ticket.id;
        this.ticketUpdate['status'] = this.ticket.status;
        this.ticketUpdate['reason'] = this.ticket.reason ?? '';
        this.titelModal = 'Không tồn tại phiếu hỗ trợ';
        if (this.ticket.id && this.ticket.TicketTypeSetting.name) {
          this.titelModal = `<div>
              <span class='text-primary d-block' style='font-size: 1.1rem'>#${this.ticket.id}</span>
              <span class='mb-0 px-0 d-block'>${this.ticket.TicketTypeSetting.name}</span>
              </div>
             `;
          this.attachments = this.image;
          //   this.getImage(this.ticket.extra);
        }
      }
    },

    updateStatusTicket() {
      const payloadUpdateTicket = {
        status: this.ticketUpdate.status,
        id: this.ticketUpdate.id,
      };

      if (payloadUpdateTicket.status === TICKET_STATUS.REJECT) {
        if (!this.ticketUpdate.reason) {
          makeToastFaile('Vui lòng nhập lí do từ chối');
          return;
        }
        payloadUpdateTicket['reason'] = this.ticketUpdate.reason;
      }

      if (!payloadUpdateTicket['status']) {
        makeToastFaile('Vui lòng chọn trạng thái');
        return;
      }

      if (!TICKET_STATUS_NAME[payloadUpdateTicket.status]) {
        makeToastFaile('Trạng thái không hợp lệ');
        return;
      }

      ApiService.post('/v2/tickets/update', payloadUpdateTicket)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 200) {
            this.fetchConfigs();
            makeToastSuccess(message);
            this.hideModal();
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          const { message } = response.data;
          makeToastFaile(message);
        });
    },

    fetchConfigs() {
      this.$emit('getListTicket');
    },

    showMultiplePreViewAttachment(indexOfFile) {
      const files = this.attachments.map((file) => {
        return {
          src: file.url,
          title: file.fileName,
        };
      });
      this.previewAttachment.imgs = files;
      this.previewAttachment.index = indexOfFile;
      this.previewAttachment.visible = true;
    },
    handleHidePreviewFile() {
      this.previewFile.visible = false;
    },
    handleHidePreviewAttachment() {
      this.previewAttachment.visible = false;
    },

    hideModal() {
      this.$bvModal.hide('detail-ticket');
    },
    typecheck(oInput) {
      var _validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];
      if (oInput.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            oInput
              .substr(
                oInput.length - sCurExtension.length,
                sCurExtension.length,
              )
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
