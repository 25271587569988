<template>
  <b-col md="12" class="mb-5">
    <p class="text-dark font-size-h5 font-weight-bold">
      Ghi chú
    </p>
    <b-row>
      <b-col md="6">
        <div class="d-flex flex-column">
          <div class="d-flex flex-column">
            <span class="font-weight-bold mr-10">
              ID Đơn Hàng:
            </span>
            <a
              href="javascript:void(0)"
              @click="detailOrder(ticket.body.orderId)"
              >{{ ticket.body.orderId }}</a
            >
            <div class="d-flex flex-column mt-3">
              <div class="d-flex font-size-md">
                <span class="font-weight-bold mr-10 " style="width: 30%"
                  >Khách Hàng:</span
                >
                <span v-if="!order.customerName">---</span>
                <span class="text-right" v-else>
                  {{ order.customerName }}
                </span>
              </div>
              <div class="d-flex font-size-md">
                <span class="font-weight-bold mr-10 " style="width: 30%"
                  >Số điện thoại:</span
                >
                <span v-if="!order.customerMobile">---</span>
                <span class="text-right" v-else>
                  {{ order.customerMobile }}
                </span>
              </div>

              <div class="d-flex font-size-md">
                <span class="font-weight-bold mr-10 " style="width: 30%"
                  >Tổng tiền:</span
                >
                <span v-if="!order.totalAmount">---</span>
                <span class="text-right" v-else>
                  {{ convertPrice(order.totalAmount) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="d-flex flex-column">
          <span class="font-weight-bold mr-10">
            ID Hóa Đơn:
          </span>
          <a
            href="javascript:void(0)"
            @click="detailBill(ticket.body.billNumber)"
            >{{ ticket.body.billNumber }}</a
          >
          <div class="d-flex flex-column mt-3">
            <div class="d-flex font-size-md">
              <span class="font-weight-bold mr-10 " style="width: 30%"
                >Khách Hàng:</span
              >
              <span v-if="!bill.customerName">---</span>
              <span class="text-right" v-else>
                {{ bill.customerName }}
              </span>
            </div>
            <div class="d-flex font-size-md">
              <span class="font-weight-bold mr-10 " style="width: 30%"
                >Số điện thoại:</span
              >
              <span v-if="!bill.customerPhone">---</span>
              <span class="text-right" v-else>
                {{ bill.customerPhone }}
              </span>
            </div>
            <div class="d-flex font-size-md">
              <span class="font-weight-bold mr-10 " style="width: 30%"
                >Tổng tiền:</span
              >
              <span v-if="!bill.totalAmount">---</span>
              <span class="text-right" v-else>
                {{ convertPrice(bill.totalAmount) }}
              </span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
import ApiService from '@/core/services/api.service';
import { convertPrice } from '../../../../utils/common';
export default {
  props: ['ticket'],
  data() {
    return {
      order: {},
      bill: {},
    };
  },
  mounted() {
    if (this.ticket.body.orderId) {
      this.getOrderDetail();
    }
    if (this.ticket.body.billNumber) {
      this.getBillDetail();
    }
  },
  methods: {
    convertPrice,
    async getOrderDetail() {
      const { data } = await ApiService.query(
        'orders' + '/' + this.ticket.body.orderId,
      );
      this.order = { ...data.data };
    },
    async getBillDetail() {
      const { data } = await ApiService.query(
        'bills' + '/' + this.ticket.body.billNumber,
      );
      this.bill = { ...data.data };
    },
    detailOrder: function(id) {
      const { href } = this.$router.resolve({
        name: 'edit-order',
        params: { id },
      });
      window.open(href, '_blank');
    },
    detailBill: function(id) {
      const { href } = this.$router.resolve({
        name: 'update-bill',
        query: { id },
      });
      window.open(href, '_blank');
    },
  },
};
</script>
